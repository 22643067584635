<template>
  <div class="home top">
    <Section center middle wrap class="hero background">
      <Grid center class="height100">
        <Column
          center
          middle
          :columnWidth="66"
          below-tablet-text-center
          :below-tablet-width="100"
          class="hero-header-text"
        >
          <h1> Specialists in employment law </h1>
        </Column>
      </Grid>
    </Section>

    <!-- Intro section -->
    <Section class="big-info image-text gradient-back" id="employees" wrap pad>
      <Grid pad center middle>

        <!-- Image Grid of Karen and Phil -->
        <Column center :below-laptop-width="100" :column-width="50" class="masonry">
          <div class="team-grid">
            <div class="masonry-team-item">                 
              <img :src="karenUrl" alt="image of Karen Coleman HR specialist" class="masonry-content team-image">
              <div class="team-project-title">
                <h3>Karen Coleman</h3>
              </div>
            </div>
            <div class="masonry-team-item">                 
              <img :src="philUrl" alt="image of Phillip Bury HR specialist" class="masonry-content team-image">
              <div class="team-project-title">
                <h3>Phillip Bury</h3>
              </div>
            </div>
          </div>
        </Column>

        <!-- Text column of employee related questions -->
        <Column
          :below-laptop-width="100"
          :column-width="50"
          below-laptop-text-center
          class="employee-section"
        >
          <h2>
            For Employees...
          </h2>
          <p>
            “Are you being made redundant?”
            <br><br>
            “Have you been offered an exit package?”
            <br><br>
            “Do you need advice on a settlement agreement?”
            <br><br>
            A settlement agreement is a legal contract containing terms (such as a lump sum payment, a reference) offered by an employer to an employee to give up employment law claims the employee may have e.g. Unfair Dismissal, Discrimination.
            <br><br>
            For employees, you are required to obtain independent legal advice on the settlement agreement for it to validly give up your potential legal claims and our experience often allows us to negotiate improved terms for you. The employer will normally meet your full costs of taking advice from us but if this is not possible, any additional costs will be agreed with you in advance.
          </p>
          <div class="button-cont">
            <a href="#contact" class="button big" style="margin-bottom: unset !important;">Contact Us</a>
          </div>
        </Column>
      </Grid>
    </Section>

    <!-- About Section -->
    <Section class="pt2 pb2 pl2 pr2 bg-colour1" id="about" wrap center>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100" class="about-us">
          <h2>
            About Us
          </h2>
          <p>
            Phillip Bury and Karen Coleman are part of a large employment law team at <em>Excello Law</em> who specialise in advising on settlement agreements.
            <br><br>
            We are both based in <em>Staffordshire</em> but can advise employees throughout England, Wales and Scotland. We each have 20 years' experience of advising employees and employers on settlement agreements. Settlement agreements are often offered with tight deadlines and we will always ensure that these are met.
          </p>
        </Column>
      </Grid>      
    </Section>

    <!-- Image Divider Section -->
    <Section class="divider-background">
    </Section>    

    <!-- Testimonials Section -->
    <Section class="image-text gradient-back testimonials-group" id="testimonials" wrap pad center middle>
      <h2>
        Testimonials from satisfied clients
      </h2>
      <Grid pad center middle class="testimonials-section">
        <!-- Text column of employee related questions -->
        <Column
          :below-laptop-width="100"
          :column-width="50"
          below-laptop-text-center
          class="testimonials-section"
        >          
          <p>
            "I've found dealing with Karen to be very easy and straightforward. Matters are always explained well and her knowledge and experience ensured that I managed to secure a good and fair settlement. I'd have no hesitation in recommending Karen for your employment law needs."
          </p>
          <h3>
            Mark G
          </h3>
          <br>

          <p>
            "After a friend's recommendation, I approached Excello Law to give me advice on my settlement agreement. I've received excellent advice regarding my options and managed to turn the agreement more into my favour thanks to that!"
          </p>
          <h3>
            Neo P.
          </h3>
          <br>

          <p>
            “Phil got in touch with me really quickly after I contacted him, and gave me correct advice after I was made redundant. The communication throughout the whole process was exceptional, Phil is very knowledgeable and helped me to get  a much better agreement that the employer originally offered me. I will definitely recommend Phil to anyone who is in need of an employment solicitor.”
          </p>
          <h3>
            Gary L
          </h3>
        </Column>

        <!-- Image Grid of Karen and Phil -->
        <Column
          :below-laptop-width="100"
          :column-width="50"
          below-laptop-text-center
          class="testimonials-section"
        >
          <p>
            “Phil got in touch with me really quickly after I contacted him, and gave me correct advice after I was made redundant. The communication throughout the whole process was exceptional, Phil is very knowledgeable and helped me to get  a much better agreement that the employer originally offered me. I will definitely recommend Phil to anyone who is in need of an employment solicitor.”
          </p>
          <h3>
            Gary L
          </h3>
          <br>

          <p>
            “Phillip recently helped me through a process where I was made redundant. Phillip broke down the process for me and gave me clear, concise advice. Excello Law were recommended to me, and I will certainly be recommending Phillip and Excello Law going forward.”
          </p>
          <h3>
            Danielle J
          </h3>
          <br>

          <p>
            "After being made aware of my impending redundancy in late 2020, I required a reputable solicitor, expert in the field of employment law, to oversee the whole process of my employment termination. It was a worrying time for me after 37 years in the same business.  Phillip Bury was recommended to me by both a family member and another solicitor, and the initial telephone conversation put my mind greatly at ease, as he explained the whole redundancy process in a clear and concise manner.  From the outset through to completion, Phillip displayed a very professional but friendly manner, and communication was excellent. The entire redundancy process went without a hitch, including some necessary modifications to the agreement which he was successful getting passed through.  Phillip is most highly recommended.”
          </p>
          <h3>
            Simon D
          </h3>
        </Column>
      </Grid>
    </Section>

    <!-- Contact Section -->
    <Section pad wrap class="above-contact-form contact-section" id="contact">
      <div class="text-center">
        <h1 class="tight">Contact Us</h1>
        <p class="pb1">
          For a no obligation chat, please call us or complete the contact form below.
        </p>
      </div>

      <Grid middle no-grow class="contact-call-grid">        
        <Column fill center :column-width="50" :below-laptop-width="100">
          <div>
            <p>
              <b><u>Phillip Bury</u></b> <br>
            </p>
            <div class="center-vertical center-horizontally">                          
              <div class="contact">
                <span><a href="tel:+44785661373">01785 661373</a></span>
              </div>
            </div>
            <div class="center-vertical center-horizontally">                          
              <div class="contact">
                <span><a href="tel:+44790779443">07790 779443</a></span>
              </div>
            </div>                
          </div>
        </Column>
        <Column fill center :column-width="50" :below-laptop-width="100">
          <div>
            <p>
              <b><u>Karen Coleman</u></b> <br>
            </p>
            <div class="center-vertical center-horizontally">                          
              <div class="contact">
                <span><a href="tel:+44782703052">01782 703052</a></span>
              </div>
            </div>
            <div class="center-vertical center-horizontally">                          
              <div class="contact">
                <span><a href="tel:+44985237002">07985 237002</a></span>
              </div>
            </div>                
          </div>
        </Column>        
      </Grid>

      <Grid center>
        <Column class="contact-form-container" :column-width="70" :below-tablet-width="100">
          <Grid pad :class="['contact-form', errors ? 'errors' : '']">
            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!nameError" for="name">Name</label>
                <label v-if="nameError" class="errorLabel" for="name"
                  >* You must enter a name</label
                >

                <input
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  :class="[nameError ? 'error' : '']"
                  v-model="name"
                  required
                />
              </div>
            </Column>
            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label for="company">Company</label>
                <input
                  type="text"
                  v-model="company"
                  placeholder="Company Name"
                  name="company"
                />
              </div>
            </Column>

            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!emailError" for="email">Email</label>
                <label v-if="emailError" class="errorLabel" for="email"
                  >* You must enter either a phone or valid email</label
                >
                <input
                  type="email"
                  v-model="email"
                  :class="[emailError ? 'error' : '']"
                  placeholder="Email"
                  name="email"
                />
              </div>
            </Column>

            <Column :column-width="50" :below-laptop-width="100">
              <div class="form-group">
                <label v-if="!telephoneError" for="telephone">Telephone</label>
                <label v-if="telephoneError" class="errorLabel" for="telephone"
                  >* You must enter either a phone or valid email</label
                >
                <input
                  type="number"
                  :class="[telephoneError ? 'error' : '']"
                  placeholder="Phone Number"
                  name="telephone"
                  v-model="telephone"
                />
              </div>
            </Column>

            <Column>
              <div class="form-group-full">
                <label v-if="!messageError" for="message">Your Message</label>
                <label v-if="messageError" class="errorLabel" for="message"
                  >* You must enter a message</label
                >
                <textarea
                  name="message"
                  placeholder="Your Message"
                  :class="[messageError ? 'error' : '']"
                  rows="8"
                  v-model="message"
                ></textarea>
              </div>
            </Column>

            <Column :column-width="100">
              <a
                :class="['button', sending ? 'sending' : '']"
                href="#"
                @click.prevent="sendMessage"
                >{{ sendButtonText }}</a
              >

              <p
                :class="[
                  'label',
                  errors || sendFailure ? 'error' : 'success',
                  !sendSucceeded && !errors && !sendFailure ? 'hide' : ''
                ]"
              >
                &nbsp;
                <span v-if="errors"
                  >Please fix the issues above and click
                  <b>Send Message</b> again</span
                >
                <span v-if="sendSucceeded"
                  >Your message was successfully sent. We will get back to you
                  shortly</span
                >

                <span v-if="sendFailure">{{ sendFailure }}</span>
              </p>
            </Column>
          </Grid>
        </Column>
      </Grid>
    </Section>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {
      name: "",
      company: "",
      email: "",
      telephone: "",
      message: "",
      nameError: false,
      emailError: false,
      telephoneError: false,
      messageError: false,
      sending: false,
      sendFailure: "",
      sendSucceeded: false,
      karenUrl: "/images/Karen-Coleman-Excello-Law-1.jpg",
      philUrl: "/images/Phil-Bury-1.jpg",
      club2Url: "/images/club/billiards.jpg",
      club3Url: "/images/club/darts.jpg",
      club4Url: "/images/club/summer-room.jpg",
      gavelUrl: "/images/pexels-sora-shimazaki-5668481.jpg"
    };
  },
  computed: {
    errors() {
      return (
        this.nameError ||
        this.emailError ||
        this.telephoneError ||
        this.messageError
      );
    },
    sendButtonText() {
      return this.sending ? "Sending..." : "Send Message";
    }
  },
  methods: {
    validEmail(email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    isEmptyText(input) {
      return input == null || input.trim() === "";
    },
    sendMessage() {
      // If we are already sending...
      if (this.sending)
        // Ignore
        return;

      // Store this
      let me = this;

      // Clear send errors
      this.sendFailure = "";
      this.sendSucceeded = false;

      // Check we have a name
      this.nameError = this.isEmptyText(this.name);

      // Email or telephone must be provided
      this.emailError = this.telephoneError =
        !this.validEmail(this.email) && this.isEmptyText(this.telephone);

      // Check we have a message
      this.messageError = this.isEmptyText(this.message);

      // If we have any errors...
      if (this.errors) {
        // Scroll to top of form
        document.querySelector(".above-contact-form").scrollIntoView({
          behavior: "smooth",
          block: "end" // scroll to bottom of target element
        });

        // Stop there
        return;
      }

      // Good to go... try sending email and setting sending status
      this.sending = true;

      this.axios
        .post("/sendemail", {
          name: this.name,
          company: this.company,
          email: this.email,
          telephone: this.telephone,
          message: this.message
        })
        .then(function(response) {
          // No longer sending
          me.sending = false;

          console.log("got response");
          console.log(response);

          // Make sure response says "Sent ok :)"
          if (response.data == "Sent ok :)") {
            // All good
            me.sendSucceeded = true;

            // Clear all fields
            me.name = me.company = me.email = me.telephone = me.message = "";
          } else {
            // Doh... unknown response
            me.sendFailure = "Unknown response from mail sender server.";
          }
        })
        .catch(function(error) {
          // No longer sending
          me.sending = false;

          console.log("got error");
          console.log(error.response);

          // Doh... show error
          me.sendFailure = me.isEmptyText(error.response.data)
            ? error.response.statusText
            : error.response.data;
        });
    }
  }  
};
</script>

<style lang="scss" scoped>

.home .background-item {
  background-color: black !important;
}

.home {
  margin-top: 4rem;
  .hero {
    color: white;
    height: 22rem;

    &.background {
      background-image: linear-gradient(bottom right, #ee3780a6,#52c0d3b8), url("/images/handshake.png");
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
    }
  }
}

.employee-section {

  h2 {
    font-size: 2.6rem;
  }

  p {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0em;
    text-transform: none;
    color: var(--color-tertiary-contrast);
  }
}
.about-us {

  h2 {
    font-size: 2.6rem;
  }

  em {
    color: var(--color-tertiary);
  }
}

.divider-background{
  background-image: linear-gradient(bottom right, #52c0d3b8, #ee3780a6), url("/images/pexels-sora-shimazaki-5668481.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 50vh;
}

.divider-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.testimonials-group {

  @include media($breakpoint-mobile) {
    text-align: center;
  }

  h2 {
    font-size: 2.6rem;
  }

  .testimonials-section {
    align-items: flex-start !important;    

    p {
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.4;
      letter-spacing: 0em;
      text-transform: none;
      color: var(--color-tertiary-contrast);  
    }

    h3 {
      color: var(--color-primary-contrast)!important;
    }

  }
}

.contact-section {
  align-items: flex-start;
  background-image: linear-gradient(bottom right, #ee3780a6,#52c0d3b8), url("/images/pexels-pixabay-48148-2.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.contact-call-grid {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #ee3780ad;
  padding: 1rem;
  border-radius: 30px;
}

.image-text {
  img {
    width: 80%;
    @include media($breakpoint-below-laptop) {
      width: 70% !important;
    }
  }
}

.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.iconic-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-gap: 1.5em;

  @include media($breakpoint-below-laptop) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    grid-gap: 1.5em;
  }
  @include media($breakpoint-below-tablet) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    grid-gap: 1.5em;
  }    
}

.iconic-header {
  padding: 1em;
  border-radius: 25px;
  background: linear-gradient(145deg, #4f95c0, #001725);
  -webkit-box-shadow: 27px 27px 55px #153c55, -27px -27px 55px #256e9b;
  box-shadow: 8px 8px 10px 0px #153c55, 0px 0px 12px #256e9b;

  h3 {
    font-size: 3em;
    margin: 0;
    line-height: 1.2em;
    color: white;

    @include media($breakpoint-below-laptop) {
      font-size: 2.5em;
    }
  }
  p {
    font-weight: bold;
    font-size: 2.5em;
    color: white;
    @include media($breakpoint-below-laptop) {
      font-size: 1.2em;
    }
    margin: 0;
  }
}

.label {
  //transition: opacity 0.5s;

  display: block;
  text-align: center;
  border-radius: 0.5em;
  padding: 1em;
  font-size: 0.9em;
  border: 1px solid;
  margin-top: 1.5em;

  &.hide {
    opacity: 0;
  }

  &.error {
    background: rgb(255, 232, 232);
    border-color: red;
    color: red;
  }

  &.success {
    background: rgb(232, 255, 232);
    border-color: green;
    color: green;
  }
}

.errors .button {
  background: red;
}

.button.sending {
  cursor: progress;
  background: green;
  &:hover {
    background: green;
  }
}

.contact-form {
  label {
    display: block;
    padding-bottom: 0.5em;
  }

  input,textarea {
    color: black;
    font-style: normal;
    font-weight: bold;
  }

  .errorLabel {
    color: red;
  }

  input,
  textarea {
    &.error {
      border-color: red !important;
      background: rgb(255, 232, 232);

      &::placeholder {
        color: red;
      }
    }

    width: 100%;
    padding: 1em;
    border: 1px solid #aaa;

    &:focus {
      outline: none !important;
      border-color: var(--color-primary);
    }
  }
}

em {
  color: var(--color-primary);
  font-style: normal;
}

.phone {
  padding-top: 3em;
  padding-bottom: 3em;
  h2 {
    font-size: 1em;
    color: var(--color-foreground-lighter);
    text-transform: uppercase;
  }

  h2 + p {
    font-size: 3em;
    margin: 0;
    font-weight: bold;
  }

  p {
    font-size: 1.5em;
  }
}

// For each menu item
.menu-item {
  // Set font size
  a {
    // Smooth transition
    transition: padding 0.2s ease, font-size 0.2s ease, color 0.2s ease,
      transform 0.2s ease-in-out;

    transform: scale(1);

    // Allow padding
    display: inline-block;

    // Padding for larger click area
    padding: 0.5em 1em;

    // Set font size
    font-size: 1.2em;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.08em;
      bottom: 0;
      left: 0;
      background-color: var(--color-primary);
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    &:hover::before {
      transform: scaleX(1);
    }

    @include media($breakpoint-below-laptop) {
      font-size: 2.2em;
    }
    @include media($breakpoint-below-tablet) {
      font-size: 1.2em;
    }

    // Highlight on hover
    &:hover {
      //transform: scale(1.2) rotate(-6deg);
      //text-decoration: underline;
    }
  }  

  // Text centered below desktop
  @include media($breakpoint-below-nav) {
    text-align: center;
  }
}
</style>
