<template>
  <div id="app" :data-page="$pageName">

    <nav data-top-menu-class="expanded" data-scroll-down-class="reduced" class="navigation-menu ">
      <div data-v-4453e750="" class="wrap">
        <div data-v-4453e750="" class="">
          <div class="grid center-horizontally no-grow" no-gutter="" data-v-4453e750=""><!----> 
            <div class="inline col align-middle">
              <a href="/" class="router-link-active">
                <img src="/images/excello-logo.png" alt="Excello Law Logo Pink and Light Blue Chevron Image" class="business-logo flex-left">
              </a>
            </div>
            <div class="col fill align-middle">
              <div data-top-menu-class="expanded" class="flex-right menu">
                <div class="grid no-grow" no-gutter="">
                  <div class="col fill below-nav-100">
                    <div class="grid no-grow nav-grid" no-gutter="">
                      <div class="menu-item col align-middle below-nav-100">
                        <a href="#employees" class="underline">For Employees</a>
                      </div>
                      <div class="menu-item col align-middle below-nav-100">
                        <a href="#about" class="underline">About Us</a>
                      </div>
                      <div class="menu-item col align-middle below-nav-100">
                        <a href="#testimonials" class="underline">Testimonials</a>
                      </div>
                      <div class="menu-item col align-middle below-nav-100">
                        <a href="#contact" class="underline">Contact Us</a>
                      </div>
                    </div>
                  </div> 
                  <div class="below-nav-hidden col" style="width: 3em;">
                  </div> 
                  <div class="col below-nav-100">
                    <div class="grid no-grow" no-gutter="">
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div class="col align-middle">
              <div class="slot-right">
              </div>
            </div>
            <div class="menu-icon col align-middle">
              <svg height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" onclick="document.getElementById('modal01').style.display='block'">
              <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"></path>
              </svg>                 
              <div id="modal01" class="modal" onclick="this.style.display='none'">
                <span class="modal-close-button">&times;</span>
                <div class="modal-content">
                  <div data-v-216b5fab="" class="menu-item col align-middle below-nav-100">
                    <a data-v-216b5fab="" href="#employees" class="underline">For Employees</a>
                  </div>
                  <div data-v-216b5fab="" class="menu-item col align-middle below-nav-100">
                    <a data-v-216b5fab="" href="#about" class="underline">About Us</a>
                  </div>
                  <div data-v-216b5fab="" class="menu-item col align-middle below-nav-100">
                    <a data-v-216b5fab="" href="#testimonials" class="underline">Testimonials</a>
                  </div>
                  <div data-v-216b5fab="" class="menu-item col align-middle below-nav-100">
                    <a data-v-216b5fab="" href="#contact" class="underline">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <router-view />

    <Section class="pad2 footer" wrap middle center>
      <div class="pb2">
        <p>
          Karen Coleman and Phillip Bury are not a firm of solicitors.  Karen Coleman and Phillip Bury are consultant solicitors at Excello Law Limited and legal services are provided by Excello Law Limited which is authorised and regulated by the Solicitors Regulation Authority under SRA number 652733. Our registered office is 5 Chancery Lane, London WC2A 1LG.
        </p>
      </div>
      <Grid middle pad no-grow>
        <Column :below-laptop-width="100" below-laptop-text-center  fill center>
          <div class="button-cont">
            <a href="https://excellolaw.co.uk/general-privacy-policy/" target="_blank" class="button" style="margin-bottom: unset !important;">Privacy Policy</a>
          </div>
        </Column>
        <Column :below-laptop-width="100" below-laptop-text-center  fill center>
          <div class="button-cont">
            <a href="https://excellolaw.co.uk/" target="_blank" class="button" style="margin-bottom: unset !important;">Excello Website</a>
          </div>
        </Column>
        <Column fill center :below-laptop-width="100">
          <div class="button-cont">
            <a href="https://excellolaw.co.uk/terms-and-conditions/" target="_blank" class="button" style="margin-bottom: unset !important;">Terms and Conditions</a>
          </div>
        </Column>        
      </Grid>      
    </Section>

    <Section wrap class="pb2 footer">
      <Grid middle no-grow>
        <Column>
        </Column>
        <Column fill center :below-laptop-width="100">
          <div class="center-vertical center-horizontally">
            <a href="https://cyberkiln.com/" target="_blank">
                Crafted by
                <br />
                <img
                  class="cyberkiln"
                  src="/images/cyberkiln.svg"
                />
              </a>
          </div>
        </Column>
        <Column :below-laptop-width="100" below-laptop-text-center>
        </Column>
      </Grid>
    </Section>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      excelloUrl: "/images/excello-logo.png",
      navigation: {
      }
    };
  },
  mounted: function() {}
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Helvetica&display=swap");
#app {
  font-family: var(--mixin-font-family);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app,
.navigation-menu {
  // Don't go less than 320px otherwise top menu is a mess
  min-width: 320px;
}

.logo-text,
.footer-logo {
  font-family: var(--mixin-font-family);
  font-size: 1.4em;
}
.logo-text {
  margin-right: 1em;

  // Match navigation colors
  color: var(--color-nav-menu-link);
  &:hover {
    // Same color on hover
    color: var(--color-nav-menu-link-hover);
  }

  @include media($breakpoint-below-laptop) {
    font-size: 4vw;
    margin-right: 0.2em;
    padding-left: 1em;
  }

  @include media($breakpoint-below-tablet) {
    font-size: 5vw;
    margin-right: 0.2em;
    padding-left: 1em;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  padding-top: 100px;
  background-color: var(--color-primary);

  .modal-close-button {
    position: absolute;
    right: 0;
    font-size: 30px !important;
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: white;
    background-color: #00000000;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 991px) {
      right: 1vw;
      top: 1vw;
      font-size: 3rem !important;
    }
  }

  .modal-close-button:hover {
    color: #fff!important;
    background-color: #f44336!important;        
  }

  .modal-content{
    margin: auto;
    background-color: #fff0;
    position: relative;
    padding: 0;
    outline: 0;
    width: 600px;
    animation: animatezoom 0.6s;
    box-shadow: 0 5px 15px #00000000!important;
    border: 1px solid #00000000!important;
    font-size: 2rem;

    img {
      height: 74vh;
      width: 40vh;
      
    }

    a {
      color: var(--color-secondary-contrast)!important;
    }

    @media (max-width: 991px) {
      width: 100vw;
      display: block;
      position: absolute;
    }
  }
}

.reduced .logo-text,
.expanded .logo-text {
  // Match navigation colors
  color: var(--color-nav-menu-link-scrolled);
  &:hover {
    // Same color on hover
    color: var(--color-nav-menu-link-scrolled-hover);
  }
}

.nav-grid {
  column-gap: 1rem;
}

a.underline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.08em;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);    
  }
}

.cyberkiln {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 35vw; 
    padding-top: 10px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 35vw;
  }  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 20vw;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 15vw;
  }
  @media only screen and (min-width: 1201px) {
    width: 15vw;
  }
}

.footer {
  background-color: var(--color-tertiary);
  color: var(--color-secondary-contrast);

  a {
    color: var(--color-dark-background-text) !important;

    &:hover {
      color: var(--color-primary) !important;
    }
  }

  p {
    margin: 0;
    display: inline-block;
    font-family: auto;
    text-align: center;
  }

  .footer-logo {
    color: var(--color-link);

    margin-bottom: 1em;

    img {
      height: 2em;
      display: inline-block;
    }
  }

  .social {
    @include media($breakpoint-below-laptop) {
      padding-top: 1em;
    }

    a {
      display: inline-block;
      transition: transform ease-in-out 0.3s;
      transform-origin: center center;

      margin-left: 1em;

      @include media($breakpoint-below-laptop) {
        margin-left: 0;
      }

      img {
        width: 2em;
      }

      &:hover {
        transform: scale(1.3);
      }
    }
  }

  .contact {
    text-align: center;

    span {
      display: inline-block;
    }

    span:not(:first-child) {
      @extend .pl;
    }
  }
}
</style>
