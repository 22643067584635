<template>
  <Column :fill="spacer" :below-nav-width="100" middle class="menu-item">
    <router-link v-if="!spacer" :to="url">
      <slot></slot>
    </router-link>
  </Column>
</template>

<script>
export default {
  props: {
    url: String,
    // If the column should act as a spacer to fill all unused space
    spacer: Boolean
  }
};
</script>

<style lang="scss" scoped>

</style>
