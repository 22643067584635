<template>
  <nav
    class="navigation-menu"
    data-top-menu-class="expanded"
    data-scroll-down-class="reduced"
  >
    <Section >
      <Grid no-gutter center no-grow>
        <Column v-if="content.image" class="menu-logo">
          <router-link to="/">
            <img :src="content.image" />
          </router-link>
        </Column>

        <Column middle class="inline">
          <slot name="left"></slot>
        </Column>

        <Column middle fill  >
          <MenuList :links="content.links" class="flex-right" />
        </Column>

        <Column middle>
          <div class="slot-right">
            <slot name="right"></slot>
          </div>
        </Column>

        <Column middle class="menu-icon">
          <a ref="expand" href="#">
            <svg
              height="32px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 32 32"
              width="32px"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
              />
            </svg>
          </a>
        </Column>
      </Grid>
    </Section>
  </nav>
</template>

<script>
export default {
  props: {
    content: Object
  },
  mounted: function() {
    // Listen on the click event
    this.$refs.expand.addEventListener("click", e => {
      // Cancel navigation
      e.preventDefault();

      // Add the desired class to all top menus
      // NOTE: Select parent so data-top-menu-class can exist on navigation itself
      var topMenus = this.$el.parentElement.querySelectorAll(
        "[data-top-menu-class]"
      );

      // For each menu...
      topMenus.forEach(function(menu) {
        // Get the value of the data-top-menu-class attribute
        var menuClass = menu.getAttribute("data-top-menu-class");

        // Add/remove class
        menu.classList.toggle(menuClass);
      });
    });
  }
};
</script>

<style lang="scss">

$menu-height: 4em;
$menu-height-small: 3em;

// Push down next item after nav menu to compensate for fixed position
// unless that element has class `top` to specify it wants to ride inside the nav menu
// for things like hero splashes
.navigation-menu + *:not(.top) {
  margin-top: 8em;
}

.nav-margin {
  transition: padding ease-in-out 1s;

  padding: 4em;

  @include media($breakpoint-below-tablet) {
    padding: 1.5em;
  }
}

.business-logo {
  height: 4rem;
  aspect-ratio: 2.6;
  transition: padding 0.7s ease, height 0.7s ease, aspect-ratio 0.7s ease;
}

.navigation-menu {
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  z-index: 1000;
  height: 5rem;
  padding: 0.5rem 2em;
  position: fixed;
  top: 0;
  width: 100%;
  transition: padding 0.7s ease, color 0.7s ease, font-size 0.7s ease, height 0.2s ease, box-shadow 0.7s ease;
  color: var(--color-nav-menu-link);
  font-family: var(--mixin-font-family);
  background-color: var(--color-nav-menu-background);

  // Use scrolled colors when expanded
  &.expanded {
    color: var(--color-nav-menu-link-scrolled);

    .menu-item a {
      color: var(--color-nav-menu-link-scrolled);

      &:hover {
        color: var(--color-nav-menu-link-scrolled-hover);
      }
    }

    .menu-icon a {
      fill: var(--color-nav-menu-link-scrolled);
    }
  }

  // Reduce size of menu
  &.reduced {
    color: var(--color-nav-menu-link);
    background-color: var(--color-nav-menu-background-scrolled);
    box-shadow: inset -4px -2px 0em 0.1em #52bfd3a3, 2px 2px 0em 0.1em #ee3780;
    padding: 0em 2em;
    height: 3.5rem;

    .business-logo {
      height: 3rem;
      aspect-ratio: 2.2;
      padding-top: 0.5rem;
      @include media($breakpoint-mobile) {
        padding-top: 0rem;
        height: 2rem;
        aspect-ratio: 2.6;
      }
      @include media($breakpoint-below-mobile) {
        height: 2rem;
        aspect-ratio: 2.2;
        padding-top: 0.5rem;
      } 
    }

    .menu-item a {
      color: var(--color-nav-menu-link-scrolled);

      &:hover {
        color: var(--color-nav-menu-link-scrolled-hover);
      }
    }

    .menu-icon a {
      fill: var(--color-nav-menu-link);

      &:hover {
        fill: var(--color-nav-menu-link-hover);
      }
    }

    .menu-logo {
      img {
        height: $menu-height-small;
      }
    }
  }

  .slot-right {
    margin-right: -0.5em;
  }

  @include media($breakpoint-below-nav) {
    .slot-right {
      margin-right: 0.5em;
    }
  }

  // Smaller when screen gets smaller padding
  @include media($breakpoint-mobile) {
    padding: 0.5em 0.5em !important;
  }
  // Smaller when screen gets smaller padding
  @include media($breakpoint-below-mobile) {
    padding: 0.5em 0.5em !important;
  }

  .menu-item a {
    // Link color
    color: var(--color-nav-menu-link);

    &:hover {
      // Link color
      color: var(--color-nav-menu-link-hover);
    }
  }

  .menu-icon {
    @extend .hidden;

    // Show when below laptop
    @extend .#{$breakpoint-below-nav}-show-block;

    svg {
      display: block;
      margin-right: 1rem;
    }

    a {
      transition: fill 0.2s ease, transform 0.2s ease;
      padding: 0.5em;
      fill: var(--color-nav-menu-link);
      display: block;

      // When expanded go main bright color
      // On hover go main bright color
      &.expanded,
      &:hover {
        // Main bright color
        fill: var(--color-nav-menu-link-hover);

        // Grow a bit
        // NOTE: Won't work on opera but its only a nice to have
        //       effect so doesn't matter
        transform-origin: 50% 50%;
        transform: scale(1.3, 1.3);
      }
    }
  }

  .spacer {
    // Fill remaining menu space to push menu items to right
    flex-grow: 1;
  }

  // Menu logo
  .menu-logo {
    a {
      // Pad nothing on left (already margin from section)
      padding: 1em;
      padding-left: 0;
      display: block;

      img {
        // Smooth transitions
        transition: height 0.2s ease;

        // Nice image size about 4 times that of text
        height: $menu-height;
        width: auto;
      }
    }
  }
}
    
</style>
